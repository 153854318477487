const addMaterial = {
  title: [{ required: true, message: '请输入名称!', trigger: 'blur' },
  ],
  category: [
    { required: true, message: '请选择类型!', trigger: 'change' },
  ],
  num: [
    { required: true, message: '请输入数量要求!', trigger: 'change' },
  ],
  sub_time_type: [
    { required: true, message: '请选择提交时间!', trigger: 'change' },
  ],
  sub_role_type: [
    { required: true, message: '请选择提交人!', trigger: 'change' },
  ],
  stage: [
    { required: true, message: '请选择阶段!', trigger: 'change' },
  ],
  post_ids: [
    { required: true, message: '请选择岗位!', trigger: 'change' },
  ],
  org_ids: [
    { required: true, message: '请选择组织机构!', trigger: 'change' },
  ],
};
export {
  addMaterial,
};
